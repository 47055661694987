<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>In Xero Opportunities</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Opportunities"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            v-model="searchTerm"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: 'module-opportunities' }">
              <v-icon left>mdi-target</v-icon>Active Opportunities
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="opportunities"
            :items-per-page="itemsPerPage"
            no-data-text="No 'in xero' opportunities found"
          >
            <template v-slot:item.customer="{item}">{{getCustomerName(item.customer_id)}}</template>
            <template
              v-slot:item.project_amount="{item}"
            >£{{Number.parseFloat(item.project_amount)}}</template>
            <template
              v-slot:item.monthly_amount="{item}"
            >£{{Number.parseFloat(item.monthly_amount)}}</template>
            <template v-slot:item.yearly_amount="{item}">£{{Number.parseFloat(item.yearly_amount)}}</template>
            <template v-slot:item.status="{item}">
              <v-menu offset-y>
                <template v-slot:activator="{on}">
                  <v-btn
                    x-small
                    depressed
                    :color="getStatusColor(item.status)"
                    v-on="on"
                    :loading="statusUpdating === item.id"
                  >
                    {{getStatusLabel(item.status)}}
                    <v-icon x-small right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(status, index) in opportunityStatuses"
                    :key="index"
                    @click="updateOpportunityStatus(item.id, status.value)"
                  >
                    <v-list-item-title>{{ status.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:item.start_date="{item}">
              <v-btn x-small depressed v-on="on" @click="openEditDateDialog(item)">
                {{getDate(item.start_date)}}
                <v-icon x-small right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.actions="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2 action-button"
                    @click="openEditOpportunity(item.id)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    small
                    depressed
                    color="orange lighten-4 orange--text"
                    v-on="on"
                    class="action-button"
                    @click="outOfXeroRequest(item.id)"
                  >
                    <v-icon small>mdi-keyboard-return</v-icon>
                  </v-btn>
                </template>
                <span>Not In Xero</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="editOpportunityForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="editOpportunity" method="post">
          <v-card-title>
            <span class="headline">Add New Opportunity</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Description *"
                  type="text"
                  v-model="editOpportunityForm.description"
                  outlined
                  dense
                  :error="editOpportunityForm.errors.hasOwnProperty('description')"
                  :error-messages="editOpportunityForm.errors['description']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Customer *"
                  v-model="editOpportunityForm.customer_id"
                  :items="customers"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  :error="editOpportunityForm.errors.hasOwnProperty('customer_id')"
                  :error-messages="editOpportunityForm.errors['customer_id']"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Status *"
                  :items="opportunityStatuses"
                  v-model="editOpportunityForm.status"
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                  :error="editOpportunityForm.errors.hasOwnProperty('status')"
                  :error-messages="editOpportunityForm.errors['status']"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-menu ref="menu" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editOpportunityForm.start_date"
                      label="Start Date *"
                      readonly
                      outlined
                      dense
                      v-on="on"
                      :error="editOpportunityForm.errors.hasOwnProperty('start_date')"
                      :error-messages="editOpportunityForm.errors['start_date']"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="editOpportunityForm.start_date" type="month" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="default" @click="menu=false">Cancel</v-btn>
                    <v-btn
                      text
                      color="success"
                      @click="$refs.menu.save(editOpportunityForm.start_date)"
                    >OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Owner *"
                  :items="teamMembers"
                  item-text="full_name"
                  item-value="id"
                  v-model="editOpportunityForm.owner_id"
                  outlined
                  dense
                  :error="editOpportunityForm.errors.hasOwnProperty('owner_id')"
                  :error-messages="editOpportunityForm.errors['owner_id']"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Project Value"
                  type="number"
                  min="0.00"
                  step="1.00"
                  prefix="£"
                  v-model="editOpportunityForm.project_amount"
                  outlined
                  dense
                  :error="editOpportunityForm.errors.hasOwnProperty('project_amount')"
                  :error-messages="editOpportunityForm.errors['project_amount']"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Monthly Value"
                  type="number"
                  min="0.00"
                  step="1.00"
                  prefix="£"
                  v-model="editOpportunityForm.monthly_amount"
                  outlined
                  dense
                  :error="editOpportunityForm.errors.hasOwnProperty('monthly_amount')"
                  :error-messages="editOpportunityForm.errors['monthly_amount']"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Yearly Value"
                  type="number"
                  min="0.00"
                  step="1.00"
                  prefix="£"
                  v-model="editOpportunityForm.yearly_amount"
                  outlined
                  dense
                  :error="editOpportunityForm.errors.hasOwnProperty('yearly_amount')"
                  :error-messages="editOpportunityForm.errors['yearly_amount']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  v-model="editOpportunityForm.notes"
                  outlined
                  dense
                  :error="editOpportunityForm.errors.hasOwnProperty('notes')"
                  :error-messages="editOpportunityForm.errors['notes']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="editOpportunityForm.dialog=false">Close</v-btn>
            <v-btn color="accent" text type="submit" :loading="editOpportunityForm.loading">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editOpportunityDateForm.dialog" width="290px">
      <v-date-picker v-model="editOpportunityDateForm.date" scrollable type="month">
        <v-spacer></v-spacer>
        <v-btn text color="default" @click="editOpportunityDateForm.dialog=false">Cancel</v-btn>
        <v-btn
          text
          color="success"
          :loading="editOpportunityDateForm.loading"
          @click="updateOpportunityDate()"
        >OK</v-btn>
      </v-date-picker>
    </v-dialog>
    <v-dialog v-model="outOfXero.dialog" persistent max-width="290px">
      <v-card>
        <v-card-title class="headline">Not Longer In Xero</v-card-title>
        <v-card-text>Are you sure you want to mark this opportunity as Not In Xero?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            depressed
            @click="outOfXero.dialog=false;outOfXero.id=null"
          >No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="outOfXero.loading"
            @click="outOfXeroConfirmed()"
          >Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        { text: "Customer", value: "customer.name" },
        { text: "Description", value: "description" },
        { text: "Project", value: "project_amount" },
        { text: "Monthly", value: "monthly_amount" },
        { text: "Yearly", value: "yearly_amount" },
        { text: "Status", value: "status" },
        { text: "Start Date", value: "start_date" },
        { text: "Actions", value: "actions" }
      ],
      itemsPerPage: -1,
      editOpportunityForm: {
        dialog: false,
        id: null,
        description: null,
        customer_id: null,
        status: null,
        project_amount: null,
        monthly_amount: null,
        yearly_amount: null,
        start_date: null,
        owner_id: null,
        notes: null,
        loading: false,
        errors: {}
      },
      editOpportunityDateForm: {
        dialog: false,
        item: null,
        date: null,
        loading: false
      },
      opportunityStatuses: [
        { value: "active", label: "Active", color: "blue white--text" },
        { value: "client-yes", label: "Client Yes", color: "default" },
        { value: "won", label: "Won", color: "success" },
        { value: "on-hold", label: "On-Hold", color: "warning" }
      ],
      outOfXero: {
        dialog: false,
        id: null,
        loading: false
      },
      statusUpdating: null
    };
  },

  computed: {
    opportunities() {
      let opportunities = this.$store.state.opportunities[
        "opportunities"
      ].filter(o => {
        return o.in_xero === 1;
      });

      if (this.searchTerm !== "") {
        opportunities = opportunities.filter(o => {
          const desc = o.description.toLowerCase();
          const name = this.getCustomerName(o.customer_id).toLowerCase();
          return (
            desc.includes(this.searchTerm.toLowerCase()) ||
            name.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return opportunities;
    },
    customers() {
      return this.$store.state.customers["customers"];
    },
    teamMembers() {
      return this.$store.state.team["teamMembers"];
    }
  },

  methods: {
    openEditOpportunity: function(id) {
      const opportunity = this.opportunities.find(o => o.id === id);

      this.editOpportunityForm = {
        dialog: true,
        id: id,
        description: opportunity.description,
        customer_id: opportunity.customer_id,
        status: opportunity.status,
        project_amount: opportunity.project_amount,
        monthly_amount: opportunity.monthly_amount,
        yearly_amount: opportunity.yearly_amount,
        start_date: this.getDate(opportunity.start_date, "-", true),
        owner_id: opportunity.owner_id,
        notes: opportunity.notes,
        loading: false,
        errors: {}
      };
    },
    editOpportunity: function() {
      const appId = this.$route.params.id;
      const opportunityId = this.editOpportunityForm.id;
      const description = this.editOpportunityForm.description;
      const customer_id = this.editOpportunityForm.customer_id;
      const status = this.editOpportunityForm.status;
      const project_amount = this.editOpportunityForm.project_amount;
      const monthly_amount = this.editOpportunityForm.monthly_amount;
      const yearly_amount = this.editOpportunityForm.yearly_amount;
      const start_date = this.editOpportunityForm.start_date;
      const owner_id = this.editOpportunityForm.owner_id;
      const notes = this.editOpportunityForm.notes;

      this.editOpportunityForm.loading = true;

      this.$store
        .dispatch("opportunities/editOpportunity", {
          appId,
          opportunityId,
          description,
          customer_id,
          status,
          project_amount,
          monthly_amount,
          yearly_amount,
          start_date,
          owner_id,
          notes
        })
        .then(() => {
          this.editOpportunityForm = {
            dialog: false,
            id: null,
            description: null,
            customer_id: null,
            status: null,
            project_amount: null,
            monthly_amount: null,
            yearly_amount: null,
            start_date: null,
            owner_id: null,
            notes: null,
            loading: false,
            errors: {}
          };
        })
        .catch(err => {
          this.editOpportunityForm.errors = err.response.data.errors;
          this.editOpportunityForm.loading = false;
        });
    },
    updateOpportunityStatus: function(id, status) {
      const appId = this.$route.params.id;
      this.statusUpdating = id;

      this.$store
        .dispatch("opportunities/updateStatus", {
          appId,
          id,
          status
        })
        .then(() => {
          this.statusUpdating = null;
        });
    },
    updateOpportunityDate: function() {
      const appId = this.$route.params.id;
      this.editOpportunityDateForm.loading = true;

      this.$store
        .dispatch("opportunities/updateStartDate", {
          appId,
          id: this.editOpportunityDateForm.item.id,
          start_date: this.editOpportunityDateForm.date
        })
        .then(() => {
          this.editOpportunityDateForm = {
            dialog: false,
            item: null,
            date: null,
            loading: false
          };
        });
    },
    outOfXeroRequest: function(id) {
      this.outOfXero.dialog = true;
      this.outOfXero.id = id;
    },
    outOfXeroConfirmed: function() {
      const appId = this.$route.params.id;
      const id = this.outOfXero.id;

      this.outOfXero.loading = true;

      this.$store
        .dispatch("opportunities/outOfXero", {
          appId,
          id
        })
        .then(() => {
          this.outOfXero = {
            dialog: false,
            id: null,
            loading: false
          };
        });
    },
    getStatusLabel: function(value) {
      const status = this.opportunityStatuses.find(s => s.value === value);

      return status.label;
    },
    getStatusColor: function(value) {
      const status = this.opportunityStatuses.find(s => s.value === value);

      return status.color;
    },
    getCustomerName: function(id) {
      const customer = this.customers.find(c => c.id === id);

      return customer.name;
    },
    getDate: function(date, divider = "/", swapped = false) {
      const d = new Date(date);
      const m = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12"
      ];

      if (swapped) {
        return d.getFullYear() + divider + m[d.getMonth()];
      }

      return m[d.getMonth()] + divider + d.getFullYear();
    },
    openEditDateDialog: function(opportunity) {
      this.editOpportunityDateForm.date = this.getDate(
        opportunity.start_date,
        "-",
        true
      );
      this.editOpportunityDateForm.item = opportunity;
      this.editOpportunityDateForm.dialog = true;
    }
  }
};
</script>

<style>
.action-row {
  width: 136px;
}
.action-button {
  padding: 0px !important;
  min-width: 28px !important;
}
</style>
